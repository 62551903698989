<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Slack Integration for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Connect this event to Slack.
        </p>

        <v-alert v-if="isConnectedWithSlackChannel" type="success">
          <p>This event is connected with a Slack channel.</p>
          <p>
            <v-btn :href="`https://slack.com/app_redirect?channel=${slackChannelId}`" target="_blank">View Channel</v-btn>
          </p>
          <p>
            <v-btn outlined @click="disconnect">Disconnect</v-btn>
          </p>
        </v-alert>
        <v-alert v-if="isConnectedWithSlackChannel && !event.published" type="info">
          <p>This event is not yet published. Publish your event before you can start.</p>
          <p>
            <v-btn :to="{name: 'eventmanagerView', params: {id:event.id}}">View and Publish</v-btn>
          </p>
        </v-alert>
        <div v-if="isConnectedWithSlackTeam && !isConnectedWithSlackChannel">
          <v-alert type="info">
            <p>This event is connected with Slack workspace <strong>{{slackTeamName}}</strong> but not yet with a channel.</p>
          </v-alert>
          <h3>Connect with a Slack channel</h3>
          <p>Open Slack and enter the following command to connect this event to that channel.</p>
          <blockquote>
          <pre>/corporatefitness connect {{event.id}}</pre>
          </blockquote>

        </div>

        <div v-if="!isConnectedWithSlackTeam">
          <v-alert type="warning">
            <p>This event is not yet connected with a Slack team. </p>
          </v-alert>
          <h3>Connect with a Slack channel</h3>
          <p>Open Slack and enter the following command:</p>
          <blockquote>
          <pre>/corporatefitness connect</pre>
          </blockquote>
          <p>Please select the <strong>Link Event</strong> option to get started.</p>
        </div>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "IntegrationsEventsCom",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      provider: null,
      siteData: siteData,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },


    async disconnect() {
      if (confirm('Are you sure you want to disconnect this event from Slack?')) {
        const response = (await eventManagerService.unlinkFromSlack(this.event.id)).data;
        await this.loadData();
        this.$helpers.toastResponse(this, response, 'Successfully disconnected from Slack.');
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Integrations', exact: true, to: { name: 'eventmanagerIntegrations', params: {id: this.event.id}} },
        { text: `Slack Integration`, disabled: true },
      ];
    },
    slackTeamName() {
      return (this.event && this.event.meta && this.event.meta.find(x => x.key == 'slack.team')||{}).val_str;
    },
    slackChannelId() {
      return (this.event && this.event.meta && this.event.meta.find(x => x.key == 'slack.channel_id')||{}).val_str;
    },
    isConnectedWithSlackTeam() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'slack.team_id');
    },
    isConnectedWithSlackChannel() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'slack.channel_id');
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

